
.done-title {
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #1E233F;
    margin-bottom: 15px;
    margin-top: 40px;
}

.done-subtitle {
    font-family: 'Mulish', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #787878;
    margin-bottom: 30px;
    text-align: center;
}