.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MuiButton-root {
  text-transform: none!important;
}

.App-link {
  color: #61dafb;
}
button:focus {
  outline: 0;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
  text-decoration: none;
}

.btn-theme {
  background: #944FF7;
  border-radius: 30px;
  border: 1px solid #944FF7;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  transition: all 0.3s ease;
  min-width: 200px;
  padding: 8px 20px;
  text-transform:none!important;
}
.btn-theme:hover {
  background: #1E233F;
  border: 1px solid #1E233F;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.btn-theme-outline {
  border: 1px solid #944FF7 !important;
  border-radius: 30px !important;
  font-family: 'Mulish', sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 22px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  justify-content: center !important;
  color: #944FF7 !important;
  transition: all 0.3s ease !important;
  min-width: 200px !important;
  padding: 8px 20px !important;
  cursor: pointer !important;
  text-decoration: none !important;
  max-height: 40px;
}
.btn-theme-outline:hover {
  background: #1E233F !important;
  border: 1px solid #1E233F !important;
  color: #ffffff !important;
  text-decoration: none !important;
}

.btn-theme-outline:disabled,
.btn-theme:disabled,
.btn-theme-outline:disabled:hover,
.btn-theme:disabled:hover {
  border: 1px solid #e5e5e5 !important;
  background: #e5e5e5 !important;
  color: #787878 !important;
  text-decoration: none !important;
  opacity: 1 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-header {
  border: 1px solid #e5e5e5;
  background: #fff;
}
.visa {
  background: #224dba;
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}
.error-text {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #ff0a0a;
  margin: 0px;
  padding-bottom: 2rem;
}
.append-custom-input-code {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  min-width: 99px !important;
  color: #1E233F;
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 0px 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.validated:focus {
  color: #000000;
  background-color: #fff;
  border-color: #1E233F !important;
  outline: 0;
  box-shadow: none;
}

.info-button {
  color: #944FF7;
}

@media screen and (max-width: 20rem) {
  .append-custom-input-code {
    font-size: 0.75rem;
  }
}