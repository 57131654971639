.app-btn-theme {
  background-color: #944FF7 !important;
  border-radius: 30px !important;
  border: 1px solid #944FF7 !important;
  font-family: "Mulish", sans-serif !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  color: #ffffff !important;
  transition: all 0.3s ease !important;
  min-width: 200px !important;
  max-height: 40px;
  padding: 8px 20px !important;
}
.app-btn-theme:hover {
  background: #1E233F !important;
  border: 1px solid #1E233F !important;
  color: #ffffff !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.app-btn-theme-outline {
  border: 1px solid #944FF7;
  border-radius: 30px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #944FF7;
  transition: all 0.3s ease;
  min-width: 200px;
  padding: 8px 20px;
  cursor: pointer;
}
.app-btn-theme-outline:hover {
  background: #1E233F;
  border: 1px solid #1E233F;
  color: #ffffff;
  text-decoration: none;
}

.app-btn-theme-outline:disabled,
.app-btn-theme:disabled,
.app-btn-theme-outline:disabled:hover,
.app-btn-theme:disabled:hover {
  border: 1px solid #e5e5e5;
  background: #e5e5e5;
  color: #787878;
  text-decoration: none;
  opacity: 1;
}
