.underline-link {
  text-decoration-line: underline;
  font-weight: 800;
  cursor: pointer;
  color: #944FF7;
}

.form-section {
  padding: 40px 0px;
  position: relative;
}
.main-title {
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #273469;
  margin-bottom: 15px;
}
.hide {
  display: none !important;
}
.sub-title {
  font-family: 'Mulish', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #787878;
  margin-bottom: 30px;
  text-align: center;
}
.resgistration-form {
  max-width: 380px;
  width: 100%;
  padding: 0px 15px;
}
.card-number {
  display: flex;
  align-items: center;
}
.card-brand {
  margin-left: -45px;
  width: 30px;
  height: 23px;
}
.center-form {
  margin: 0 auto;
}
.inner-body {
  width: 100%;
  margin: 50px auto 50px;
  position: relative;
}
.form-header {
  position: relative;
  margin-bottom: 20px;
}
.form-title {
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #273469;
  margin-bottom: 8px;
  position: relative;
}
.form-title span {
  display: inline-block;
  background: #fff;
  padding-right: 10px;
}
.form-title:after {
  content: '';
  width: 100%;
  height: auto;
  border: 1px solid #273469;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 12px;
  z-index: -1;
}
.form-subtitle {
  font-family: 'Mulish', sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #273469;
  margin-bottom: 0px;
}
.form-information {
  font-size: 14px;
  line-height: 15px;
  color: #273469;
}
.email-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.email-title {
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #1E233F;
  margin-bottom: 16px;
}
.email-sub-title {
  font-family: 'Mulish', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #787878;
  margin-bottom: 30px;
}

.email-msg-text {
  font-family: 'Mulish', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #787878;
  margin-bottom: 10px;
}

.email-msg-text span {
  color: #944FF7;
  font-weight: 600;
}
.email-img {
  margin-bottom: 16px;
  max-width: 600px;
}
.email-img img {
  max-width: 100%;
}
.thankyou-text {
  font-family: 'Mulish', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #787878;
  margin-bottom: 30px;
}
.form-group {
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}
.form-label {
  font-family: 'Mulish', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #787878;
}
.form-control {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  height: 40px;
  padding: 0px 16px;
  font-family: 'Mulish', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  background-color: #fff;
}
.form-control:focus {
  color: #000000;
  background-color: #fff;
  border-color: #944FF7;
  outline: 0;
  box-shadow: none;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #ff0a0a;
  box-shadow: none;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #ff0a0a;
  background-image: none;
  padding: 0px 16px;
  /* background-image: url('../../assets/img/info-red.svg'); */
}
.form-control.is-visa {
  padding-right: 42px;
  background-image: url('../../../assets/img/visa.svg');
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 30px;
}
.info-img {
  width: 15px;
  height: 15px;
  margin-left: 6px;
  display: inline-block;
  position: relative;
  top: -2px;
}
.info-img img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.error-text {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ff0a0a;
  margin: 0px;
  /* position: absolute;
  top: 70px; */
}
.form-group .append-input {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #1E233F;
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 0px 16px;
}
.input-group-custom {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.append-custom-input {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #1E233F;
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 0px 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-group-country {
  flex: 1 1 auto;
}
.email-form {
  max-width: 350px;
  width: 100%;
  margin-top: 30px;
}

.email-form .error-text {
  top: 40px;
}
.promo-code-div {
  margin: 1rem 0 2rem;
}
.custom-box {
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 0.25rem;
  width: 100%;
  height: 40px;
}
.right-box {
  position: inherit;
  top: 35%;
  min-width: 300px;
  max-width: 400px;
  margin-left: 1rem;
}

.daily-rectangle {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 30px;
}
.daily-rectangle:before {
  content: '';
  display: block;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-right-color: #e5e5e5;
}
.daily-rectangle:after {
  content: '';
  display: block;
  position: absolute;
  left: -22px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border: 11px solid transparent;
  border-right-color: white;
}
.daily-head {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #1E233F;
  border-bottom: 1px solid #e5e5e5;
  padding: 6px 30px;
}
.daily-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 30px 0px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #787878;
}
.daily-list span:first-child {
  flex: 0 0 150px;
}
.daily-list.total {
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}
.daily-text {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #787878;
  margin-bottom: 16px;
}
.theme-link {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #944FF7;
  cursor: pointer;
}
.theme-link:hover {
  color: #1E233F;
}
.error-muted {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #787878;
}
.cursor {
  cursor: not-allowed;
  color: #fff;
}

.RFS-StepperContainer.stepclass {
  position: relative;
  padding: 0px 0px;
  margin: 30px auto;
  max-width: 600px;
}
.stepclass:after {
  content: '';
  background: #e5e5e5;
  border-radius: 30px;
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0px;
  top: 15px;
  z-index: -1;
}
.stepclass .RFS-ConnectorContainer {
  display: none;
}
.stepclass .stepclassName {
  display: none;
}
.stepclass .stepclassName.active,
.stepclass .stepclassName.completed {
  display: block;
}
.stepclass .RFS-StepMain {
  position: relative;
}
.stepclass .RFS-StepContainer {
  padding-left: 0px;
  padding-right: 0px;
}
.stepclass .stepclassName.completed:after {
  content: '';
  background: #944FF7;
  width: 100%;
  height: 5px;
  position: absolute;
  left: 0px;
  top: 15px;
  z-index: 1;
}
.stepclass .stepclassName.active:after {
  content: '';
  background: #944FF7;
  height: 5px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 15px;
  z-index: 1;
}
.stepclass .RFS-StepCircle {
  width: 30px !important;
  height: 30px !important;
  background: transparent !important;
}

.stepclass .RFS-StepCircle:focus {
  outline: none;
}
.stepclass .RFS-StepCircleContent {
  position: relative;
  width: 30px;
  height: 30px;
  background: #944FF7;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: -1px 4px 4px rgba(30, 133, 130, 0.17);
  z-index: 2;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  margin: 0 auto;
}
.stepclass .RFS-StepCircleContent.completed {
  width: 20px;
  height: 20px;
  background: #944FF7;
  box-shadow: -1px 4px 4px rgba(30, 133, 130, 0.17);
  border: 0px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
}

.stepclass .RFS-Label {
  display: block;
  margin-top: 6px;
  text-align: center;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  color: #1E233F;
}

.custom-alert-box {
  background: #fddbdb;
  border-radius: 3px;
  border-color: #fddbdb;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ff0a0a;
  padding: 8px 20px 8px 40px;
}
.custom-alert-box .close {
  position: absolute;
  top: 0px;
  left: 16px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #ff0a0a;
  font-size: 17px;
  opacity: 1;
}
.custom-alert-box-success {
  background: #D6EDEC;
  border-radius: 3px;
  border: none; 
  color: #1E8582;
}
.custom-alert-box-success .close {
  background: #D6EDEC;
  color: #1E8582;
}
.discount {
 color: #944FF7;
}
.btn-wrap {
  margin: 0px auto;
  margin-top: 50px;
  max-width: 600px;
}

.done-step {
  font-family: 'Raleway';
  font-style: 'normal';
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
}
.done-text {
  font-family: 'Mulish', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}
.disabled-class {
  background-color: #e5e5e5;
  color: #787878;
  border-color: #e5e5e5;
}
.user-list {
  display: flex;
  align-items: center;
  margin: 16px 0px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.user-list img {
  margin-right: 16px;
}

.plan-container {
  justify-content: flex-end;
}
.owner-contact, .terms-and-conditions {
  font-size: 0.875rem;
}

@media screen and (max-width: 64rem) {
  .right-box {
    margin: 0 1.5rem;
    flex-direction: column-reverse;
  }
  .plan-container {
    justify-content: center;
    height: 100%;
  }
  .custom-alert-box {
    max-width: 100%;
  }
}
@media screen and (max-width: 50rem) {
  .plan-container {
    flex-direction: column;
    justify-content: center;
  }
  .resgistration-form {
    margin: 0 auto;
  }
  .right-box{
    padding: 0.625rem;
    display: flex;
    align-self: center;
    width: 70%;
    margin: 0 50px;
    flex-wrap: wrap;
  }
  .daily-rectangle:after, .daily-rectangle:before {
    display: none;
  }
  .row {
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 37.5rem) {
  .login-container {
    padding-top: 6rem;
  }
  .main-title {
    font-size: 1.75rem;
  }
  .login-container {
    width: 70%;
  }
  .underline-link {
    text-align: center;
  }
}

@media screen and (max-width: 30rem) {
  .login-container {
    padding-top: 4rem;
  }
  .main-title {
    font-size: 1.5rem;
  }
  .sub-title {
    font-size: 1rem;
  }
  .login-footer {
    flex-direction: column;
    padding-bottom: 0;
  }
  .forgot-password {
    margin-top: 0.5625rem;
  }
  .remember-password {
    padding: 1.5rem 0 1.5rem 0;
  }
  .form-label, input.form-control, select.form-control,
  .theme-link, .daily-text, .owner-contact, 
  .terms-and-conditions {
    font-size: 0.875rem;
  }
  .form-title, .daily-head, .daily-list.total {
    font-size: 1.2rem;
  }
  .btn-wrap {
    margin-top: 2rem;
    flex-direction: column-reverse;
  }
  .btn-wrap .btn-theme-outline {
    margin-top: 1rem;
  }
  .form-information {
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: #273469;
  }
  .underline-link {
    margin-top: 0.625rem;
  }
}

@media screen and (max-width: 20rem) {
  .main-title {
    font-size: 1.25rem;
  }
  .sub-title, .daily-list {
    font-size: 0.875rem;
  }
  .login-footer {
    flex-direction: column;
    padding-bottom: 0;
  }
  .form-label, input.form-control, select.form-control,
  .theme-link, .daily-text, .owner-contact,
  .terms-and-conditions {
    font-size: 0.75rem;
  }
  .form-title, .daily-head, .daily-list.total {
    font-size: 1rem;
  }
}