.loader-wrap {
    width: 100%;
    height: 100%;
    min-height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;

    img {
        width: 7rem;
    }

}

.customer-search-loader {
    height: 0%;
}